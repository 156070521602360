var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchbar-container", style: _vm.rightContainerPadding },
    [
      _c(
        "b-field",
        {
          staticClass: "search-input-field full-height",
          class: { "is-dark": _vm.dark, "is-gray": _vm.gray }
        },
        [
          _c(
            "b-input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "on-clickaway",
                      rawName: "v-on-clickaway",
                      value: _vm.hideHistory,
                      expression: "hideHistory"
                    }
                  ],
                  ref: "input",
                  class: {
                    searchbar: true,
                    "is-bare": _vm.bare,
                    rounded: _vm.rounded
                  },
                  attrs: {
                    icon: "magnify",
                    "icon-clickable": "",
                    value: _vm.value,
                    placeholder: _vm.placeholder
                  },
                  on: {
                    input: function(value) {
                      return _vm.$emit("input", value)
                    },
                    "icon-click": function($event) {
                      return _vm.$emit("click")
                    },
                    focus: _vm.onFocus,
                    blur: function($event) {
                      _vm.originalQuery = _vm.value
                    }
                  },
                  nativeOn: {
                    keyup: [
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        ) {
                          return null
                        }
                        return _vm.onEnter.apply(null, arguments)
                      },
                      function($event) {
                        return _vm.onKeyup.apply(null, arguments)
                      }
                    ],
                    keydown: function($event) {
                      return _vm.onKeydown.apply(null, arguments)
                    }
                  }
                },
                "b-input",
                Object.assign({}, _vm.$props, _vm.$attrs),
                false
              ),
              _vm.$listeners
            )
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showHistory && _vm.visibleSearchHistory.length > 1
              ? _c(
                  "div",
                  { staticClass: "history" },
                  _vm._l(_vm.visibleSearchHistory, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "history-item",
                        class: { active: index === _vm.currentItem },
                        on: {
                          click: function($event) {
                            return _vm.onHistoryItemClick(item.name, item.type)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "history-content" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getIcon(item.type),
                              alt: "history icon"
                            }
                          }),
                          _c("div", {
                            staticClass: "history-item-text",
                            domProps: { innerHTML: _vm._s(item.name) }
                          })
                        ]),
                        item.type === "history"
                          ? _c(
                              "div",
                              {
                                staticClass: "btn-remove",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.removeHistoryItem(item)
                                  }
                                }
                              },
                              [_vm._v(" Remove ")]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("div", { staticClass: "searchbar-btn" }, [
        _c(
          "div",
          {
            class: {
              "searchbar-btn-clear": true,
              "ph-3": true,
              "pv-1": true,
              hidden: !_vm.value
            },
            on: {
              click: function($event) {
                return _vm.$emit("clear")
              }
            }
          },
          [_vm._v(" Clear ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }