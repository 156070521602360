<template>
  <div class="searchbar-container" :style="rightContainerPadding">
    <b-field
      :class="{ 'is-dark': dark, 'is-gray': gray }"
      class="search-input-field full-height"
    >
      <b-input
        ref="input"
        v-on-clickaway="hideHistory"
        :class="{ searchbar: true, 'is-bare': bare, rounded }"
        icon="magnify"
        icon-clickable
        :value="value"
        :placeholder="placeholder"
        v-bind="{ ...$props, ...$attrs }"
        @input="value => $emit('input', value)"
        @icon-click="$emit('click')"
        @focus="onFocus"
        @keyup.native.enter.exact="onEnter"
        @keyup.native="onKeyup"
        @keydown.native="onKeydown"
        @blur="originalQuery = value"
        v-on="$listeners"
      ></b-input>
      <transition name="fade">
        <div
          v-if="showHistory && visibleSearchHistory.length > 1"
          class="history"
        >
          <div
            v-for="(item, index) in visibleSearchHistory"
            :key="index"
            class="history-item"
            :class="{ active: index === currentItem }"
            @click="onHistoryItemClick(item.name, item.type)"
          >
            <div class="history-content">
              <img :src="getIcon(item.type)" alt="history icon" />
              <!-- eslint-disable-next-line -->
              <div class="history-item-text" v-html="item.name"></div>
            </div>
            <div
              v-if="item.type === 'history'"
              class="btn-remove"
              @click.stop="removeHistoryItem(item)"
            >
              Remove
            </div>
          </div>
        </div>
      </transition>
    </b-field>
    <div class="searchbar-btn">
      <div
        :class="{
          'searchbar-btn-clear': true,
          'ph-3': true,
          'pv-1': true,
          hidden: !value
        }"
        @click="$emit('clear')"
      >
        Clear
      </div>
    </div>
  </div>
</template>

<script>
import historySvg from '@/assets/icons/history.svg'
import searchSvg from '@/assets/search-light.svg'
import {
  deleteSearchHistoryItem,
  getSearchSearchSuggestion
} from '@/services/searchAutocompleteService'
import { debounce } from 'lodash'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'SearchBar',
  mixins: [clickaway],
  props: {
    placeholder: {
      type: String,
      default: 'Search'
    },
    value: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    gray: {
      type: Boolean,
      default: false
    },
    bare: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    noHistory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showHistory: false,
      currentItem: -1,
      originalQuery: '',
      autoCompleteItems: []
    }
  },
  computed: {
    ...mapState({
      workspaces: state => state.workspaces.workspaces
    }),
    ...mapGetters(['currentWorkspaceMember', 'resourceTypeFilter']),
    currentWorkspace() {
      return this.workspaces[this.$route.params.workspace_id]
    },
    visibleSearchHistory() {
      let items = []
      if (this.originalQuery)
        items.push({
          name: this.originalQuery,
          type: 'query'
        })
      items.push(
        ...this.autoCompleteItems.map(aci => ({
          ...aci,
          name: aci.name.replace(
            this.originalQuery.toLowerCase(),
            `<b>${this.originalQuery.toLowerCase()}</b>`
          )
        }))
      )
      return items
    },
    rightContainerPadding() {
      const padding = this.value ? 6 : 0
      return `--search-container-padding: ${padding}ch;`
    }
  },
  watch: {
    showHistory(newVal) {
      if (!newVal) this.currentItem = -1
    },
    currentItem() {
      if (this.currentItem < 0) return
      if (this.value !== this.visibleSearchHistory[this.currentItem].name)
        this.$emit(
          'input',
          this.visibleSearchHistory[this.currentItem].name
            .replaceAll('<b>', '')
            .replaceAll('</b>', '')
        )
    }
  },
  mounted() {
    document.addEventListener('keyup', this.nextItem)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.nextItem)
  },
  methods: {
    getIcon(type) {
      if (type === 'history') return historySvg
      return searchSvg
    },
    onEnter() {
      this.showHistory = false
      this.$refs.input.$refs.input.blur()
    },
    onKeydown(e) {
      if (e.keyCode == 38 || e.keyCode == 40) return e.preventDefault()
    },
    async onKeyup(e) {
      if (e.keyCode == 38 || e.keyCode == 40 || e.keyCode === 13)
        return e.preventDefault()
      this.currentItem = -1
      this.originalQuery = this.value
      if (!this.noHistory) {
        this.$nextTick(async () => {
          this.debounceCalls()
        })
      }
    },
    debounceCalls: debounce(async function() {
      await this.getAutoCompleteHistory()
    }, 150),
    async onFocus() {
      if (!this.noHistory) {
        await this.getAutoCompleteHistory()
        this.showHistory = true
      }
      this.originalQuery = this.value
    },
    nextItem() {
      if (event.keyCode == 38) {
        if (this.currentItem <= 0)
          return (this.currentItem = this.visibleSearchHistory.length - 1)
        return this.currentItem--
      }
      if (event.keyCode == 40) {
        if (this.currentItem === this.visibleSearchHistory.length - 1)
          return (this.currentItem = 0)
        return this.currentItem++
      }
    },
    hideHistory() {
      this.showHistory = false
    },
    focus() {
      this.$refs.input.focus()
    },
    async getAutoCompleteHistory() {
      this.autoCompleteItems = await getSearchSearchSuggestion(
        this.currentWorkspace.uuid,
        this.currentWorkspaceMember.uuid,
        this.value,
        this.resourceTypeFilter || 'all'
      )
    },
    onHistoryItemClick(query, type) {
      this.$emit('input', query.replaceAll('<b>', '').replaceAll('</b>', ''))
      this.$nextTick(() => {
        this.$emit(
          type === 'history' ? 'historyItemClick' : 'autocompleteItemClick'
        )
        this.showHistory = false
      })
    },
    async removeHistoryItem(item) {
      try {
        await deleteSearchHistoryItem(
          this.currentWorkspace.uuid,
          this.currentWorkspaceMember.uuid,
          item.uuid
        )
        this.autoCompleteItems.splice(
          this.autoCompleteItems.findIndex(i => i.uuid === item.uuid),
          1
        )
      } catch (e) {
        this.$console.debug('Error when removing search history item', e)
        this.$toast.error(e, 'removing search history item')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search-input-field {
  position: relative;
  margin-bottom: 0 !important;

  .searchbar {
    width: 100%;
    background-color: transparent;

    &::v-deep input {
      background-color: #f1f2f3;
      border: 0.5px solid #f1f2f3;
      transition: all 0.3s ease-in-out;

      &:hover {
        border: 0.5px solid rgba(0, 0, 0, 0.08);
        background-color: #f9f9fa;
      }

      &:focus {
        border: 0.5px solid rgba(0, 0, 0, 0.08);
        background-color: white;
      }
    }

    &.rounded {
      &::v-deep input {
        border-radius: 180px !important;
      }
    }
  }
}

.history {
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 2.7rem;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 999;

  .history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    transition: all 0.3s ease-in-out;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &.active {
      background-color: #f1f2f3;
    }

    &:hover {
      background-color: #f1f2f3;
    }

    .history-content {
      display: flex;
      align-items: center;

      .history-item-text {
        margin-left: 0.5rem;
      }
    }

    .btn-remove {
      color: #8f9399;
      transition: all 0.3s ease-in-out;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }
}

.searchbar-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.searchbar-btn {
  position: absolute;
  top: 0;
  right: 0.5ch;
  height: 100%;
  width: var(--search-container-padding);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5ch;
  padding-right: 1ch;

  &-clear {
    color: $blue;
    border-radius: 15px;
    z-index: 10;

    &:hover {
      cursor: pointer;
      background: #ededed;
    }

    &.hidden {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  &-doctype {
    z-index: 11;
  }
}

::v-deep .mdi-magnify::before {
  color: $body-text !important;
  font-size: 18px !important;
}

.searchbar {
  ::v-deep input {
    padding-right: var(--search-container-padding);
  }
}

.is-gray {
  background-color: $search-grey;

  ::v-deep input {
    background-color: transparent;
  }
}

.is-bare {
  ::v-deep input {
    border: none;
  }
}

::v-deep input::placeholder {
  color: #a6acbe;
  font-size: 1rem !important;
}
</style>
